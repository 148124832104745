@charset "utf-8";

@import 'vars';
@import 'functions';
@import 'mixins';
@import url('//fonts.googleapis.com/css?family=Open+Sans:400,400italic,600,700,300');
@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css');
@import "skel";


  @include skel-breakpoints((
    desktop: '(min-width: 737px)',
    tablet: '(min-width: 737px) and (max-width: 1200px)',
    mobile: '(max-width: 736px)'
  ));

  @include skel-layout((
    reset: 'full',
    boxModel: 'border',
    grid: true,
    conditionals: true,
    containers: true,
    breakpoints: (
      desktop: (
        containers: 1200px,
        grid: (
          gutters: (25px, 25px)
        ),
      ),
      tablet: (
        containers: 1000px,
        grid: (
          gutters: (20px, 20px)
        ),
      ),
      mobile: (
        containers: (100%, true),
        grid: (
          gutters: (15px, 15px)
        ),
      )
    )
  ));

/* Basic */

  body {
    background: #f5f5f5 url(/images/bg01.jpg) repeat;
    font-family: 'Open Sans', sans-serif;
    font-size: 10pt;
    color: #8c8c8c;
  }

  form {
    input, select, textarea {
      -webkit-appearance: none;
    }
  }

  br.clear {
    clear: both;
  }

  h1, h2, h3 {
    font-weight: 300;
    color: #262626;
  }

  p, ul, ol, dl, table {
    margin-bottom: 1.75em;
  }

  p {
    line-height: 1.75em;
  }

  a {
    color: #91B362;
  }

  section, article {
    > :last-child, &:last-child {
      margin-bottom: 0;
    }
  }

  .image {
    display: inline-block;

    img {
      display: block;
      width: 100%;
    }
  }

  .image-full {
    display: block;
    width: 100%;
    margin: 0 0 2em 0;
  }

  .image-left {
    float: left;
    margin: 0 2em 1em 0;
  }

  .image-centered {
    display: block;
    margin: 0 0 2em 0;

    img {
      margin: 0 auto;
      width: auto;
    }
  }

  .button {
    @include vendor('transition', 'background-color 0.25s ease-in-out');
    background: #91B362;
    text-decoration: none;
    font-style: italic;
    color: #FFFFFF;

    &:hover {
      background: #a1c372;
    }

    &:active {
      background: #81a352;
    }
  }

  ul {
    &.style1 {
      li {
        position: relative;
        line-height: 1.5em;
        padding: 0.75em 0 0.75em 0;
        border-top: solid 1px #E8E8E8;
      }

      a {
        text-decoration: underline;
        color: #8d8d8d;

        &:hover {
          text-decoration: none;
        }
      }

      li.first {
        padding-top: 0;
        border-top: 0;
      }
    }

    &.style2 {
      cursor: default;

      li {
        position: relative;
        display: inline-block;
        margin-right: 0.75em;
      }

      a {
        @include vendor('transition', 'background-color 0.25s ease-in-out');
        display: block;
        background: #c0c0c0;
        white-space: nowrap;
        width: 42px;
        height: 42px;
        overflow: hidden;
        color: #fff;

        &:before {
          display: block;
          font-size: 24px;
          width: 42px;
          height: 42px;
          line-height: 42px;
          text-align: center;
        }

        &:hover {
          background: #a0a0a0;
        }
      }
    }

    &.style3 {
      li {
        padding: 1.50em 0em 1.90em 0em;
        border-top: 1px solid #e8e8e8;
      }

      a {
        text-decoration: none;
        color: #6e6d6d;

        &:hover {
          text-decoration: underline;
        }
      }

      .posted {
        font-size: 0.90em;
        color: #b6b6b6;
      }

      .first {
        padding-top: 0em;
        border-top: none;
      }
    }

    &.style4 {
      margin-bottom: 2em;
      text-align: center;
      overflow: hidden;

      li {
        @include vendor('transition', 'background-color 0.25s ease-in-out');
        display: inline-block;
        margin: 0 1em 0 1em;
        padding: 0.70em 1em;
        text-decoration: none;
        font-size: 1.25em;
        font-style: italic;
        color: #787878;
        cursor: pointer;

        &:hover, &.active {
          background: #ededed;
          color: #454545;
        }
      }
    }

    &.style5 {
      li {
        position: relative;
        display: block;
        overflow: hidden;
        line-height: 1.5em;
        margin: 0 0 1.25em 0;
      }

      a {
        display: block;
        text-decoration: underline;
        color: #8d8d8d;
        margin-left: 5.5em;

        &:hover {
          text-decoration: none;
        }
      }

      .date {
        position: absolute;
        left: 0;
        top: 0;
        margin-right: 1em;
        text-decoration: none;
        color: #b3b3b3;
        width: 5em;
      }
    }
  }

  .category {
    display: none;
  }

/* Icons */

  .icon {
    text-decoration: none;

    &:before {
      display: inline-block;
      font-family: FontAwesome;
      font-size: 1.25em;
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

/* Wrappers */

  #header-wrapper {
    overflow: hidden;
  }

  #footer-wrapper {
    overflow: hidden;
  }

/* Bar */

  #bar {
    position: relative;
    overflow: hidden;
    background: #101010 url(images/bg02.jpg) no-repeat center top;
    background-size: 100% 100%;

    h2 {
      color: #FFFFFF;
    }

    p {
      color: #727272;
    }
  }

/* Slider */

  #slider {
    background: #f4f4f4;
  }

/* Poptrox */

  .poptrox-popup {
    @include vendor('box-sizing', 'content-box');
    box-shadow: 0px 25px 25px 0px rgba(0, 0, 0, 0.25);

    .caption {
      font-style: italic;
    }
  }

/* Main */

  #main {
    overflow: hidden;
    background: #FFFFFF;
  }

/* Footer */

  #footer {
    overflow: hidden;
    background: url(/images/bg03.png) no-repeat center top;

    h2 {
      color: #000000;
    }
  }

/* Copyright */

  #copyright {
    overflow: hidden;
    border-top: 1px solid #d7d7d7;
    text-align: center;
    color: #b8b8b8;
  }

/* Desktop */

  @include breakpoint(desktop) {

    /* Basic */

      h2 {
        font-size: 2em;
      }

      section, article {
        margin-bottom: 3em;
      }

      .button {
        padding: 25px 30px;
        font-size: 2em;
      }

      .button-top {
        display: none;
      }

    /* Wrappers */

      #header-wrapper {
        padding: 3em 0 2em 0;
      }

    /* Header */

      #header {
        position: relative;

        h1 {
          letter-spacing: -1px;
          font-size: 2.5em;
          font-weight: 300;
          line-height: 2em;
          padding: 0 0 0 0.75em;
          margin: 0;

          a {
            text-decoration: none;
            color: #000000;
          }
        }
      }

    /* Nav */

      #nav {
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 2em;

        > ul > li > ul {
          display: none;
        }

        ul {
          margin: 0;
          padding: 0;
          text-align: center;
        }

        li {
          float: left;
          display: inline-block;
          padding: 0 1.5em 0 1.5em;
        }

        a {
          @include vendor('transition', 'color 0.25s ease-in-out');
          display: inline-block;
          padding-bottom: 0.65em;
          text-decoration: none;
          font-size: 1.20em;
          font-weight: 300;
          font-style: italic;
          color: #a2a2a2;
          outline: 0;

          &:hover {
            color: #454545;
          }
        }

        .active {
          a {
            color: #454545;
          }
        }

        .current_page_item {
          a {
            color: #454545;
            border-bottom: 4px solid #91B362;
          }
        }
      }

      .dropotron {
        background: #fff;
        background: rgba(255, 255, 255, 0.97);
        padding: 1.5em;
        line-height: 2.5em;
        min-width: 13em;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);

        a, span {
          font-weight: 300;
          font-style: italic;
          color: #a2a2a2;
          text-decoration: none;
        }

        li {
          &:hover {
            > a, > span {
              color: #454545;
            }
          }
        }

        &.level-0 {
          margin-top: 1em;

          &:after {
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            margin-left: -10px;
            border-bottom: solid 10px rgba(255, 255, 255, 0.97);
            border-left: solid 10px transparent;
            border-right: solid 10px transparent;
          }
        }
      }

    /* Bar */

      #bar {
        padding: 3.5em 3em 3.5em 3em;

        h2 {
          font-size: 1.75em;
          font-weight: 300;
          margin: 0 !important;
        }

        p {
          position: absolute;
          right: 3em;
          top: 50%;
          margin-top: -0.7em;
          font-size: 1.25em;
          font-weight: 300;
        }
      }

    /* Slider */

      #slider {
        margin: 0 auto;
        padding: 0;
        width: 1200px;
        height: 635px;
        position: relative;

        .viewer {
          width: 1200px;
          margin: 0 auto;
          overflow: hidden;

          .reel {
            display: none;

            .slide {
              position: relative;

              img {
                width: 1200px;
                height: 635px;
              }
            }
          }
        }

        .caption-shadow {
          position: absolute;
          width: 100%;
          background: url(/images/bg04.png) no-repeat center top;
          height: 50px;
          top: 180px;
        }

        .captions {
          position: absolute;
          top: 195px;
          width: 1200px;
          height: 180px;
          padding-top: 30px;
          background: #fff;
          background: rgba(255, 255, 255, 0.9);

          .captionLine {
            display: block;
            padding-top: 15px;
            padding-left: 120px;
            font-weight: 300;

            h2 {
              margin: 0px;
              padding: 0px 0px 0px 0px;
              letter-spacing: -1px;
              font-size: 2.50em;
            }

            p {
              width: 50%;
              margin: 0px;
              padding-top: 10px;
            }

            .button {
              position: absolute;
              top: 50px;
              right: 120px;
              z-index: 10;
            }
          }
        }

        .navigation {
          position: absolute;
          top: 195px;
          height: 180px;
          right: 0px;
          width: 1200px;
        }

        .previous-button, .next-button {
          @include vendor('transition', 'opacity 0.25s ease-in-out');
          outline: 0;
          position: absolute;
          opacity: 0.5;
          width: 8em;
          height: 100%;
          top: 0;
          overflow: hidden;
          text-decoration: none;

          &:before {
            display: inline-block;
            font-family: FontAwesome;
            font-size: 128px;
            text-decoration: none;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            line-height: 170px;
            width: 100%;
            height: 100%;
            color: #ccc;
            text-align: center;
          }

          &:hover {
            opacity: 1.0;
          }
        }

        .previous-button {
          left: 0;

          &:before {
            content: '\f104';
          }
        }

        .next-button {
          right: 0;

          &:before {
            content: '\f105';
          }
        }
      }

    /* Main */

      #main {
        padding: 0em 0em 5em 0em;

        h2 {
          margin: 0em 0em 1em 0em;
          letter-spacing: -1px;
        }
      }

      .homepage #main {
        padding-bottom: 0;
      }

    /* Content */

      .no-sidebar #content {
        padding: 3em 3em 0em 3em;
      }

      .left-sidebar #content {
        padding: 3em 3em 0em 0em;
      }

      .right-sidebar #content {
        padding: 3em 0em 0em 3em;
      }

    /* Sidebar */

      .left-sidebar {
        #sidebar {
          padding: 3em 3em 0em 3em;
        }
      }

      .right-sidebar {
        #sidebar {
          padding: 3em 3em 0em 3em;
        }
      }

    /* Footer */

      #footer {
        padding-top: 6em;

        h2 {
          padding: 0em 0em 1em 0em;
          font-size: 1.50em;
        }
      }

    /* Copyright */

      #copyright {
        margin-top: 4em;
        padding: 3em 0em 10em 0em;
      }

  }

/* Tablet */

  @include breakpoint(tablet) {

    /* Basic */

      body {
        min-width: 1000px;
        font-size: 9.5pt;
      }

    /* Wrappers */

      #header-wrapper {
        padding: 1em 0 1em 0;
      }

    /* Header */

      #header {
        h1 {
          font-size: 2em;
        }
      }

    /* Nav */

      #nav {
        top: 1.5em;
      }

    /* Bar */

      #bar {
        padding: 2em 3em;
      }

    /* Slider */

      #slider {
        width: 1000px;
        height: 529px;

        .viewer {
          width: 1000px;

          .reel .slide img {
            width: 1000px;
            height: 529px;
          }
        }

        .caption-shadow {
          top: 180px;
        }

        .captions {
          width: 1000px;
          top: 155px;

          .captionLine .button {
            top: 52px;
            font-size: 1.50em;
          }
        }

        .navigation {
          width: 1000px;
        }

        .previous-button, .next-button {
          margin-top: -55px;
        }
      }

    /* Footer */

      #footer {
        background-size: 100% auto;
        padding-top: 5em;
      }

    /* Copyright */

      #copyright {
        margin-top: 3em;
        padding: 3em 0em 5em 0em;
      }

  }

/* Mobile */

  #navPanel, #titleBar {
    display: none;
  }

  @include breakpoint(mobile) {

    /* Basic */

      html, body {
        overflow-x: hidden;
      }

      body {
        line-height: 1.75em;
        font-size: 9.5pt;
        letter-spacing: 0;
      }

      h2, h3, h4, h5, h6 {
        font-size: 1.5em;
      }

      section, article {
        clear: both;
        margin: 0px 15px !important;
      }

      ul {
        &.style2 {
          overflow: hidden;
          padding-bottom: 1em;
        }

        &.style4 {
          margin-bottom: 2em;
          text-align: center;

          li {
            display: block;
            margin-bottom: 0.30em;
          }

          a {
            display: block;
            padding: 0.70em 1em;
            text-decoration: none;
            font-size: 1.25em;
            font-style: italic;
            color: #787878;
          }
        }
      }

      .button {
        display: block;
        text-align: center;
        font-size: 1.25em;
        padding: 1em 0 1em 0;
      }

    /* Off-Canvas Navigation */

      #page-wrapper {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transition', 'transform #{_duration(navPanel)} ease');
        padding-bottom: 1px;
        padding-top: 44px;
      }

      #titleBar {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transition', 'transform #{_duration(navPanel)} ease');
        display: block;
        height: 44px;
        left: 0;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: _misc(z-index-base) + 1;
        background: #101010 url('images/bg02.jpg') no-repeat left top;
        background-size: 100% 100%;

        .title {
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          line-height: 44px;
          font-size: 1.1em;
          font-weight: 300;
          color: #fff;
          text-align: center;
        }

        .toggle {
          position: absolute;
          left: 0;
          top: 0;
          text-indent: -9999px;
          width: 60px;
          height: 44px;
          z-index: 1;

          &:before {
            content: '';
            position: absolute;
            left: 4px;
            top: 1px;
            width: 25px;
            height: 30px;
            background: url('/images/toggle.svg') 6px 15px no-repeat;
            opacity: 0.15;
          }

          &:active:before {
            opacity: 0.5;
          }
        }
      }

      #navPanel {
        @include vendor('backface-visibility', 'hidden');
        @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
        @include vendor('transition', ('transform #{_duration(navPanel)} ease'));
        display: block;
        height: 100%;
        left: 0;
        overflow-y: auto;
        position: fixed;
        top: 0;
        width: _size(navPanel);
        z-index: _misc(z-index-base) + 2;
        background: #101010;
        background-size: 100% 100%;

        .link {
          display: block;
          color: #666;
          text-decoration: none;
          height: 44px;
          line-height: 44px;
          border-top: 1px solid #222;
          padding: 0 1em 0 1em;
          font-style: italic;

          &:first-child {
            border-top: 0;
          }
        }

        .indent-1 {
          display: inline-block;
          width: 1em;
        }

        .indent-2 {
          display: inline-block;
          width: 2em;
        }

        .indent-3 {
          display: inline-block;
          width: 3em;
        }

        .indent-4 {
          display: inline-block;
          width: 4em;
        }

        .indent-5 {
          display: inline-block;
          width: 5em;
        }

        .depth-0 {
          color: #fff;
        }
      }

      body {
        &.navPanel-visible {
          #page-wrapper {
            @include vendor('transform', 'translateX(#{_size(navPanel)})');
          }

          #titleBar {
            @include vendor('transform', 'translateX(#{_size(navPanel)})');
          }

          #navPanel {
            @include vendor('transform', 'translateX(0)');
          }
        }
      }

    /* Wrappers */

      #main-wrapper {
        margin: 10px 10px 0 10px;
      }

      #footer-wrapper {
        margin: 0 10px 10px 10px;
      }

    /* Header */

      #header, #nav {
        display: none;
      }

    /* Bar */

      #bar {
        overflow: hidden;
        margin-top: -3px;
        background-size: 100% 100%;
        padding: 1em 0 1em 0;

        h2, p {
          text-align: center;
          font-weight: 300;
        }

        h2 {
          display: block;
          margin: 0 !important;
          font-size: 1.1em;
        }

        p {
          display: none;
        }
      }

    /* Slider */

      #slider {
        position: relative;

        .viewer .reel {
          display: none;

          .slide img {
            width: 100%;
            line-height: 0;
          }
        }

        .captions {
          overflow: hidden;
          padding: 30px 20px 20px 20px;
          background: #fff;
          text-align: center;

          h2 {
            margin: 0 0 0.75em 0;
          }
        }

        .navigation {
          display: none;
        }
      }

    /* Main */

      #main {
        margin: 0em;
        padding: 0em 0em 3em 0em;

        > .row > .\31 2u:first-child {
          margin-top: 0 !important;
        }

        h2 {
          margin: 0em 0em 1.5em 0em;
        }

        .image-left {
          float: none;
          width: 100%;
          margin-bottom: 0.75em;
        }
      }

      .homepage #main {
        padding-bottom: 0;
      }

    /* Content */

      .no-sidebar, .left-sidebar, .right-sidebar {
        #content {
          padding: 2em 0em 0em 0em;
        }
      }

      /* Sidebar */

      .left-sidebar {
        #sidebar {
          padding: 3em 0em 0em 0em;
        }
      }

      .right-sidebar {
        #sidebar {
          padding: 3em 0em 0em 0em;
        }
      }

    /* Footer */

      #footer {
        padding-top: 4em;
        background-size: 100% auto;

        h2 {
          margin: 0em 0em 0.50em 0em;
        }

        section {
          overflow: hidden;
          padding-bottom: 2em;
        }
      }

    /* Copyright */

      #copyright {
        margin-top: 4em;
        padding: 3em 0em 5em 0em;
      }

  }
